import { Alert as MUIAlert, Snackbar } from "@mui/material";
import { useContext } from "react";
import { GlobalContext } from "../services/Context";

export const Alert = () => {
  const { alert, setAlert } = useContext(GlobalContext);

  const handleClose = () => setAlert({ ...alert, show: false });

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={4000}
      open={alert.show}
      onClose={handleClose}
      style={{zIndex:9999999}}
    >
      <MUIAlert
        onClose={handleClose}
        severity={alert.type}
        sx={{ width: "100%" }}
      >
        {alert.message}
      </MUIAlert>
    </Snackbar>
  );
};
