import { useContext, useEffect, useState, useRef } from "react";

import classes from "../styles/pages/Activity.module.css";
import { Parent } from "../components/Parent";
import { SearchInput } from "../components/SearchInput";
import { Table } from "../components/Table";
import { checkTokenExists } from "../tools/security";
import { GlobalContext } from "../services/Context";
import { Get } from "../services/Services";
import { transactionsTableColumns } from "../data/TableColumns";
import {
  columns,
  BootstrapDialog,
  StyledDialogActions,
} from "../data/DialogData";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function Activity() {
  useEffect(checkTokenExists(), []);

  const { setAlert, prodEnv, userData } = useContext(GlobalContext);

  const [tableLoading, setTableLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [params, setParams] = useState({
    search: "",
    sorting: { field: "date", sort: "desc" },
    page: 1,
  });
  let debounceTimer = useRef(null);
  const [dialogData, setDialogData] = useState([]);
  const [totalPending, setTotalPending] = useState(0);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [recipient, setRecipient] = useState("");
  const [open, setOpen] = useState(false);
  const handleClickOpen = (props) => {
    getDialogData(props);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDialogData([]);
    setTotalPending(0);
    setTotalSuccess(0);
    setRecipient("")

  };

  const getDialogData = async (props) => {
    console.log(props);
    setTableLoading(true);
    let modelData = await Get(
      `/transactions?userID=${userData.user_id}&receiverID=${props.row.receiver}`,
      `v${props.userData.business_version}`
    );
    let transactions = modelData.data.transactions;
    setDialogData(
      transactions.map((transaction) => ({
        id: transaction.referenceNo,
        request_amount: `${transaction.request_amount}${
          transaction.receivedCurrency
            ? " " + transaction.receivedCurrency.name
            : " USDc"
        }`,
        received_amount: transaction.received_amount
          ? `${transaction.received_amount}${
              transaction.sentCurrency
                ? " " + transaction.sentCurrency.name
                : " USDc"
            }`
          : "TBD",
        sent_amount: transaction.sent_amount
          ? `${transaction.sent_amount}${
              transaction.sentCurrency
                ? " " + transaction.sentCurrency.name
                : " USDc"
            }`
          : "TBD",
        status: transaction.status,
        date: new Date(transaction.createdAt).toLocaleDateString("en-UK", {
          year: "2-digit",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }),
        Recipient: `${transaction.receiver.firstName} ${transaction.receiver.lastName}`,
      }))
    );
    let pendingTotal = 0;
    let successTotal = 0;

    // Calculate the totals
    transactions.forEach((transaction) => {
      if (transaction.status === "Pending") {
        pendingTotal += parseFloat(transaction.request_amount);
      } else if (transaction.status === "Success") {
        successTotal += parseFloat(transaction.request_amount);
      }
    });
    setRecipient(
      `${transactions[0].receiver.firstName} ${transactions[0].receiver.lastName}`
    );

    // Update the state with the calculated totals
    setTotalPending(pendingTotal);
    setTotalSuccess(successTotal);

    setTableLoading(false);
  };

  const getTransactions = async (params) => {
    setTableLoading(true);
    let userID = `userID= ${userData.user_id}`;
    let pageParams = `page=${params?.page || 1}`;
    let searchParams = params?.search ? `referenceNo=${params.search}` : "";
    let sortingParams = `sortBy=${
      params?.sorting?.field.toLowerCase() || "date"
    }&sortOrder=${params?.sorting?.sort || "desc"}`;

    let endpoint;
    let version;
    if (userData.business_version === "1") {
      endpoint = `/transactions?${pageParams}&${userID}&${searchParams}&${sortingParams}`;
      version = "v1";
    } else {
      endpoint = `/transactions?${pageParams}&${searchParams}&${sortingParams}`;
      version = "v2";
    }

    let { success, data, error } = await Get(endpoint, version);
    if (success) {
      let transactions;
      ({ transactions, ...data } = data);

      setPaginationData(data);

      setTableData(
        transactions
          ? userData.business_version === "1"
            ? transactions.map(
                ({
                  referenceNo,
                  createdAt,
                  receiver,
                  wallet,
                  sent_amount,
                  sentCurrency,
                  received_amount,
                  receivedCurrency,
                  request_amount,
                  status,
                }) => ({
                  id: referenceNo,
                  TransactionID: referenceNo,
                  Date: new Date(createdAt).toLocaleDateString("en-UK", {
                    year: "2-digit",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  }),
                  Recipient: {
                    avatar: receiver?.avatar,
                    name: receiver
                      ? `${receiver?.firstName} ${receiver?.lastName}`
                      : `${wallet?.address}${
                          wallet?.memo ? `:${wallet?.memo}` : ""
                        }`,
                  },
                  request_amount: `${request_amount}${
                    receivedCurrency ? " " + receivedCurrency.name : " USDc"
                  }`,
                  sent_amount: sent_amount
                    ? `${sent_amount}${
                        sentCurrency ? " " + sentCurrency.name : " USDc"
                      }`
                    : "TBD",
                  received_amount: received_amount
                    ? `${received_amount}${
                        sentCurrency ? " " + sentCurrency.name : " USDc"
                      }`
                    : "TBD",
                  Status: status,
                  receiver: receiver.id,
                })
              )
            : transactions.map(
                ({
                  referenceNo,
                  createdAt,
                  sender,
                  wallet,
                  sent,
                  received,
                  status,
                }) => ({
                  id: referenceNo,
                  TransactionID: referenceNo,
                  Date: new Date(createdAt).toLocaleDateString("en-UK", {
                    year: "2-digit",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  }),
                  Recipient: {
                    avatar: sender?.avatar,
                    name: sender
                      ? `${sender?.firstName} ${sender?.lastName}`
                      : `${wallet?.address}${
                          wallet?.memo ? `:${wallet?.memo}` : ""
                        }`,
                  },
                  sent_amount: `${sent.amount} ${sent.currency?.toUpperCase()}`,
                  received_amount: `${
                    received.amount
                  } ${received.currency?.toUpperCase()}`,
                  Status: status,
                })
              )
          : []
      );
    } else {
      setAlert({
        show: true,
        message: error.message,
        type: "error",
      });
    }
    setTableLoading(false);
  };

  useEffect(() => {
    getTransactions();
  }, [prodEnv]);

  return (
    <Parent>
      <SearchInput
        label="Search by Transaction ID"
        value={params.search}
        onChange={(value) => setParams({ ...params, search: value })}
        onSearch={() => getTransactions(params)}
      />

      <div className={classes.tableTitle}>Previous Transactions</div>

      <Table
        rowCount={paginationData.totalTransactions}
        columns={transactionsTableColumns}
        rows={tableData}
        isLoading={tableLoading}
        debounceTimer={debounceTimer}
        onPaginationChange={(value) => {
          let newParams = { ...params, page: value };
          setParams(newParams);
          getTransactions(newParams);
        }}
        onSortingChange={(value) => {
          let newParams = { ...params, sorting: value };
          setParams(newParams);
          getTransactions(newParams);
        }}
        hideRequestAmountColumn={userData.business_version !== "1"}
        onRowClick={(props) => {
          userData.business_version === "1"
            ? handleClickOpen({ ...props, userData })
            : "";
        }}
      />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          All Transactions With <strong>{recipient}</strong>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Table
            columns={columns}
            rows={dialogData}
            isLoading={tableLoading}
            onSortingChange={getTransactions}
            hideFooter
            hideRequestAmountColumn={userData.business_version !== "1"}
          />
        </DialogContent>
        <StyledDialogActions>
          <div>
          <p>Total Success Transactions: {totalSuccess} USDc</p>
            <p>Total Pending Transactions: {totalPending} USDc</p>
            
          </div>
        </StyledDialogActions>
      </BootstrapDialog>
    </Parent>
  );
}
