import { Button, InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { Link } from "react-router-dom";

import classes from "../styles/components/SignInForm.module.css";
import { buttonStyle, CustomTextField } from "../styles/muiStyles";
import { Icon } from "./Icon";
import { validateEmail } from "../tools/validation";
import { colors } from "../styles/Colors";

export const SignInForm = ({ onSubmit }) => {
  const [inputData, setInputData] = useState({});
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const signIn = () => {
    if (!validateEmail(inputData.email)) {
      setEmailError("Please enter a valid email.");
    } else {
      onSubmit(inputData)
    }
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.inputsContainer}>
        <div className={classes.mainLogoContainer}>
          <Icon source={require("../assets/logo.png")} />
        </div>

        <div className={classes.titleContainer}>
          <div className={classes.title}>Welcome back!</div>
          <div className={classes.subTitle}>Sign in to continue</div>
        </div>

        <div className={classes.inputContainer}>
          <div className={classes.textFieldLabel}>Email</div>
          <CustomTextField
            placeholder="Enter email"
            variant="standard"
            className={classes.textField}
            value={inputData.email || ""}
            onChange={(e) =>
              setInputData({ ...inputData, email: e.target.value })
            }
            sx={{
              "& .MuiInput-underline:before": {
                borderBottomColor: Boolean(inputData.email) && colors.secondary,
              },
              "& .MuiInput-underline": {
                color: Boolean(inputData.email) && colors.secondary,
              },
            }}
          />
          <div
            className={classes.forgotPasswordContainer}
            style={{
              justifyContent: "start",
              marginTop: ".2em",
            }}
          >
            <div className={classes.invalidError}>{emailError}</div>
          </div>
        </div>

        <div className={classes.inputContainer}>
          <div className={classes.textFieldLabel}>Password</div>
          <CustomTextField
            type={showPassword ? "text" : "password"}
            placeholder="Enter password"
            variant="standard"
            className={classes.textField}
            value={inputData.password || ""}
            onChange={(e) =>
              setInputData({ ...inputData, password: e.target.value })
            }
            sx={{
              "& .MuiInput-underline:before": {
                borderBottomColor:
                  Boolean(inputData.password) && colors.secondary,
              },
              "& .MuiInput-underline": {
                color: Boolean(inputData.password) && colors.secondary,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <VisibilityOffIcon fontSize="small" />
                    ) : (
                      <VisibilityIcon fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.forgotPasswordContainer}>
            <Link to="/ForgotPassword" className={classes.forgotPassword}>
              Forgot password?
            </Link>
          </div>
        </div>
      </div>

      <div className={classes.bottomContainer}>
        <div className={classes.bottomRow}>
          <Button
            variant="contained"
            className={classes.button}
            style={buttonStyle}
            onClick={signIn}
          >
            Login
          </Button>
        </div>
        {/* <div className={classes.bottomRow}>
          <span className={classes.bottomText}>Don't have an account?</span>{" "}
          <Link className={classes.bottomRegister}>Register</Link>
        </div> */}
      </div>
    </div>
  );
};
