// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, IconButton,} from "@mui/material";
import { useContext, useState,useEffect } from "react";

// import { languages } from "../data/Languages";
import { GlobalContext } from "../services/Context";
import { colors } from "../styles/Colors";
import classes from "../styles/components/Header.module.css";
// import { NotificationBadge } from "../styles/muiStyles";
import { EnvSwitch } from "./EnvSwitch";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
export const Header = () => {
  const {  setOpenNavbar } = useContext(GlobalContext);  //{ language, setLanguage,} 
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [Adminname, setAdminname] = useState("");

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  useEffect(() => {
    let userdata = sessionStorage.getItem("userData");
    let name = JSON.parse(userdata).name;
    setAdminname(name);
  
  }, []);
  return (
    <div className={classes.mainContainer}>
      <div className={classes.envSwitch}>
        <EnvSwitch />
      </div>
      <div className={classes.burger}>
        <IconButton onClick={() => setOpenNavbar(true)}>
          <MenuIcon fontSize="large" sx={{ color: colors.primary }} />
        </IconButton>
      </div>

      <div className={classes.endContainer}>
        {/* <NativeSelect
          defaultValue={language}
          inputProps={{ name: "language" }}
          disableUnderline
          onChange={(e) => {
            setLanguage(e.target.value);
            sessionStorage.setItem("lang", e.target.value);
          }}
        >
          {languages.map(({ value, label }, key) => (
            <option value={value} key={key}>
              {label}
            </option>
          ))}
        </NativeSelect>

        <IconButton>
          <NotificationBadge variant="dot" badgeContent={1} color="secondary">
            <NotificationsNoneIcon sx={{ color: colors.primary }} />
          </NotificationBadge>
        </IconButton> */}

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar sx={{ bgcolor: colors.primary }} src="">
              {stringAvatar(Adminname)}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key={"logout"}>
              <Link
                style={{ textDecoration: "none", color: "#000", textAlign: "center" }}
                to={"../"}
                onClick={() => {
                  handleCloseUserMenu;
                  sessionStorage.clear();
                }}
              >
                Logout
              </Link>
            </MenuItem>
          </Menu>
        </Box>
      </div>
    </div>
  );
};

function stringAvatar(name) {
  const firstLetters = name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase();

  return firstLetters;
}