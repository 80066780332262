
import ReactLoading from "react-loading";
import { colors } from "../styles/Colors";

import classes from "../styles/components/Loading.module.css"

export const Loading = () => {
  return (
    <div className={classes.mainContainer}>
      <ReactLoading
        type="bars"
        color={colors.primary}
        width={120}
      />
    </div>
  );
};
