import { Paper, ThemeProvider } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Loading } from "../components/Loading";
import { useNavigate } from "react-router-dom";

import { LoginBackground } from "../components/LoginBackground";
import { SignInForm } from "../components/SignInForm";
import { GlobalContext } from "../services/Context";
import { Post } from "../services/Services";
import { theme } from "../styles/muiStyles";
import classes from "../styles/SignIn.module.css";
import { Alert } from "../components/Alert";
import { checkTokenExists } from "../tools/security";
import { OTPForm } from "../components/OTPForm";

export default function SignIn() {
  const { loading, setLoading, setAlert, setUserData } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const [auth, setauth] = useState(false);

  useEffect(checkTokenExists(true), []);

  const signIn = async (body) => {
    setLoading(true);
    let { success, data, error } = await Post("/login", body, true);
    setLoading(false);

    if (success) {
      let token;
      ({ token, ...data } = data);
      if (token) {
        setUserData(data);
        sessionStorage.setItem("userData", JSON.stringify(data));
        sessionStorage.setItem("token", token);
        navigate("/Dashboard");
      } else {
        setauth(true);
      }
    } else {
      setAlert({ show: true, message: error.message, type: "error" });
    }
  };

  const verify = async (code) => {
    setLoading(true);
    let { success, data, error } = await Post("/verify_otp", code, true);
    setLoading(false);

    if (success) {
      let token;
      ({ token, ...data } = data);
      if (token) {
        setUserData(data);
        sessionStorage.setItem("userData", JSON.stringify(data));
        sessionStorage.setItem("token", token);
        navigate("/Dashboard");
      }
    } else {
      setAlert({ show: true, message: error.message, type: "error" });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.pageContainer}>
        <LoginBackground />

        <div className={classes.formPageContainer}>
          {!auth ? (
            <Paper className={classes.formContainer} elevation={3}>
              <SignInForm onSubmit={signIn} />
            </Paper>
          ) : (
            <Paper
              className={classes.formContainer}
              style={{ height: "300px" }}
              elevation={3}
            >
              <OTPForm onVerify={verify} />
            </Paper>
          )}
        </div>
      </div>

      <Alert />
      {loading && <Loading />}
    </ThemeProvider>
  );
}
