import classes from "../styles/components/LoginBackground.module.css"

export const LoginBackground = () => {
  return (
    <div className={classes.skewedBackground}>
      <div
        className={classes.skewedBackgroundLines}
        style={{
          backgroundImage: `url(${require("../assets/signInBackgroundLines.png")})`,
        }}
      />
    </div>
  );
};
