import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { colors } from "../styles/Colors";

import classes from "../styles/components/SearchInput.module.css";

export const SearchInput = ({
  label = "",
  value = "",
  onChange = () => {},
  onSearch = () => {},
}) => {
  const borderStyle = {
    borderColor: colors.primary,
    borderWidth: 2,
    borderLeftWidth: 4,
  };

  return (
    <TextField
      type="text"
      label={label}
      className={classes.searchInput}
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "6px",
          "& fieldset": borderStyle,
          "&:hover fieldset": borderStyle,
          "&.Mui-focused fieldset": borderStyle,
        },
      }}
      onKeyDown={(e) => {
        if (e.code == "Enter") onSearch();
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => onSearch()}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
