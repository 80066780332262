import React from "react";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
// import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
// import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
// import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
// import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

export const NavbarData = [
  {
    title: "Dashboard",
    icon: <DashboardOutlinedIcon />,
    link: "/Dashboard",
  },
  {
    title: "Activity",
    icon: <HubOutlinedIcon />,
    link: "/Activity",
  },
  // {
  //   title: "Documentation",
  //   icon: <DescriptionOutlinedIcon />,
  //   link: "/Documentation",
  // },
  {
    title: "Developers",
    icon: <DataObjectOutlinedIcon />,
    link: "/Developers",
  },
  // {
  //   title: "Make a Deposit",
  //   icon: <CurrencyExchangeOutlinedIcon />,
  //   link: "/Deposit",
  // },
  // {
  //   title: "Make a Withdrawal",
  //   icon: <PaidOutlinedIcon />,
  //   link: "/Withdrawal",
  // },
  {
    title: "Support",
    icon: <SupportAgentOutlinedIcon />,
    link: "/Support",
  },
  // {
  //   title: "Sign Out",
  //   icon: <LogoutOutlinedIcon />,
  //   link: "/SignIn",
  // },
];
