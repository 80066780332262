import { Button, InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState, useContext } from "react";
import { GlobalContext } from "../services/Context";
import classes from "../styles/components/ForgotPasswordVerificationForm.module.css";
import { buttonStyle, CustomTextField } from "../styles/muiStyles";
import { Icon } from "./Icon";
import { validateOTP } from "../tools/validation";
import { colors } from "../styles/Colors";
import { Alert } from "./Alert";

export const ForgotPasswordVerificationForm = ({
  translationValue,
  onNext,
  onSubmit,
}) => {
  const [otpError, setOTPError] = useState("");
  const [inputData, setInputData] = useState({});
  const [pwData, setpwData] = useState({});
  const [confirmpwData, setconfirmpwData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { setAlert } = useContext(GlobalContext);
  const next = () => {
    if (!validateOTP(inputData.code)) {
      setOTPError("Please enter a valid otp.");
    } else {
      onNext(inputData);
    }
  };
  const submit = () => {
    if (pwData.password != confirmpwData.confirmPassword) {
      setAlert({ show: true, message: "Password does not match", type: "error" });
    }
    else{
      onSubmit(pwData);
    }
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.inputsContainer}>
        <div className={classes.mainLogoContainer}>
          <Icon source={require("../assets/logo.png")} />
        </div>

        <div
          className={classes.translatableContainer}
          style={{ height: translationValue == 120 && "280px" }}
        >
          <div
            className={classes.translatableForm}
            style={{ left: `${-translationValue}%` }}
          >
            <div className={classes.titleContainer}>
              <div className={classes.title}>Verification</div>
              <div className={classes.subTitle}>
                Enter your verification code to reset your password.
              </div>
            </div>

            <div className={classes.inputContainer}>
              <div className={classes.textFieldLabel}>Verification Code</div>
              <CustomTextField
                placeholder="Enter your verification code"
                variant="standard"
                className={classes.textField}
                value={inputData.code || ""}
                onChange={(e) =>
                  setInputData({ ...inputData, code: e.target.value })
                }
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor:
                      Boolean(inputData.code) && colors.secondary,
                  },
                  "& .MuiInput-underline": {
                    color: Boolean(inputData.code) && colors.secondary,
                  },
                }}
              />
              <div className={classes.invalidErrorContainer}>
                <div className={classes.invalidError}>{otpError}</div>
              </div>
            </div>
          </div>

          <div
            className={classes.translatableForm}
            style={{ left: `${120 - translationValue}%` }}
          >
            <div className={classes.titleContainer}>
              <div className={classes.title}>Password</div>
              <div className={classes.subTitle}>Enter your password.</div>
            </div>

            <div className={classes.inputContainer}>
              <div className={classes.textFieldLabel}>Password</div>
              <CustomTextField
                placeholder="Enter your password"
                variant="standard"
                className={classes.textField}
                value={pwData.password || ""}
                type={showPassword ? "text" : "password"}
                onChange={(e) =>
                  setpwData({ ...pwData, password: e.target.value })
                }
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor:
                      Boolean(pwData.password) && colors.secondary,
                  },
                  "& .MuiInput-underline": {
                    color: Boolean(pwData.password) && colors.secondary,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOffIcon fontSize="small" />
                        ) : (
                          <VisibilityIcon fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className={classes.inputContainer}>
              <div className={classes.textFieldLabel}>Confirm Password</div>
              <CustomTextField
                placeholder="Confirm your password"
                variant="standard"
                className={classes.textField}
                value={confirmpwData.confirmPassword || ""}
                type={showConfirmPassword ? "text" : "password"}
                onChange={(e) =>
                  setconfirmpwData({
                    ...confirmpwData,
                    confirmPassword: e.target.value,
                  })
                }
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor:
                      Boolean(confirmpwData.confirmPassword) && colors.secondary,
                  },
                  "& .MuiInput-underline": {
                    color:
                      Boolean(confirmpwData.confirmPassword) && colors.secondary,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <VisibilityOffIcon fontSize="small" />
                        ) : (
                          <VisibilityIcon fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>

        <div className={classes.bottomRow}>
          <Button
            variant="contained"
            className={classes.button}
            style={buttonStyle}
            onClick={() => {
              translationValue == 120 ? submit() : next();
            }}
          >
            Next
          </Button>
        </div>
      </div>
      <Alert />
    </div>
  );
};
