import { useContext } from "react";
import { Link } from "react-router-dom";

import classes from "../styles/components/Navbar.module.css";
import LogoWhite from "../assets/logoWhite.svg";
import { Icon } from "./Icon";
import { NavbarData } from "../data/NavbarData";
import { EnvSwitch } from "./EnvSwitch";
import { GlobalContext } from "../services/Context";

export const Navbar = () => {
  const { openNavbar, setOpenNavbar,userData } = useContext(GlobalContext);

  return (
    <div
      role="presentation"
      className={classes.scrollableContainer}
      style={{ left: openNavbar && 0 }}
      onClick={() => setOpenNavbar(false)}
    >
      <div className={classes.mainContainer}>
        <div className={classes.logoContainer}>
          <Icon source={LogoWhite} style={{ width: "75%" }} />
        </div>

        <div className={classes.navbarList}>
          {NavbarData.map(({ link, icon, title }, key) => (
            <Link
              key={key}
              to={link}
              className={`${classes.navbarRow} ${
                window.location.pathname == link && classes.activeRow
              }`}
            >
              <div className={classes.navbarIcon}>{icon}</div>
              <div className={classes.navbarTitle}>{title}</div>
            </Link>
          ))}
        </div>

        <div className={classes.bottomListContainer}>
          <div className={classes.envSwitch}>
            <EnvSwitch textStyle={{ color: "white" }} />
          </div>
          <Link
            className={classes.bottomListItem}
            to={userData.business_version === "1"?"https://luxuriant-mandevilla-5fa.notion.site/Empowch-Business-API-e88a915c466d47838045839156c219fb":
              "https://vast-flyaway-e3e.notion.site/Empowch-business-API-docs-360d9d9a71a54ca1b313e7291f130f1e"
            }
            target="_blank"
          >
            Documentations
          </Link>
          <Link
            className={classes.bottomListItem}
            to={"https://www.empowch.com/terms-of-service/"}
            target="_blank"
          >
            Terms of Service
          </Link>
          <Link
            className={classes.bottomListItem}
            to={"https://www.empowch.com/privacy-policy/"}
            target="_blank"
          >
            Privacy Policy
          </Link>
          <span className={classes.version}>(V 1.0.1)</span>
        </div>
      </div>
    </div>
  );
};
