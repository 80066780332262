import { Paper, ThemeProvider } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ForgotPasswordVerificationForm } from "../components/ForgotPasswordVerificationForm";
import { LoginBackground } from "../components/LoginBackground";
import classes from "../styles/ForgotPasswordVerification.module.css";
import { theme } from "../styles/muiStyles";
import { checkTokenExists } from "../tools/security";
import { Post } from "../services/Services";
import { GlobalContext } from "../services/Context";
import { Loading } from "../components/Loading";
import { Alert } from "../components/Alert";

export default function ForgotPasswordVerification() {
  const navigate = useNavigate();
  const [translationValue, setTranslationValue] = useState(0);
  const { loading, setLoading, setAlert } = useContext(GlobalContext);

  useEffect(checkTokenExists(true), []);

  const onNext = async (body) => {
    setLoading(true);
    let { success, data, error } = await Post("/verify_forgot_password", body,true);
    setLoading(false);
    if (success) {
      sessionStorage.setItem("token", data.token)
      setTranslationValue(120);
    } else {
      setAlert({ show: true, message: error.message, type: "error" });
    }
  };
  const onSubmit = async (body) => {
    setLoading(true);
    let { success, error } = await Post("/reset_password", body,true);
    setLoading(false);
    if (success) {
      sessionStorage.clear();
      navigate("/");
    } else {
      setAlert({ show: true, message: error.message, type: "error" });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.pageContainer}>
        <LoginBackground />

        <div className={classes.formPageContainer}>
          <Paper
            className={classes.formContainer}
            elevation={3}
            style={{ height: translationValue == 120 && "400px" }}
          >
            <ForgotPasswordVerificationForm
              translationValue={translationValue}
              onNext={onNext}
              onSubmit={onSubmit}
            />
          </Paper>
        </div>
      </div>
      <Alert />
      {loading && <Loading />}
    </ThemeProvider>
  );
}
