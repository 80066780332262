import { Parent } from "../components/Parent";

export default function Withdrawal() {
  return (
    <Parent>
      <div>Withdrawal page</div>
      <div>test</div>
      <div>test</div>
      <div>test</div>
    </Parent>
  );
}
