import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Activity from "../pages/Activity";
import Documentation from "../pages/Documentation";
import Developers from "../pages/Developers";
import Support from "../pages/Support";
import Withdrawal from "../pages/Withdrawal";
import Deposit from "../pages/Deposit";
import ForgotPassword from "../pages/ForgotPassword";
import ForgotPasswordVerification from "../pages/ForgotPasswordVerification";
import SignIn from "../pages/SignIn";

export const Routing = () => (
  <BrowserRouter basename={"/"}>
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/ForgotPassword" element={<ForgotPassword />} />
      <Route
        path="/ForgotPasswordVerification"
        element={<ForgotPasswordVerification />}
      />
      <Route path="/Dashboard" element={<Dashboard />} />
      <Route path="/Activity" element={<Activity />} />
      <Route path="/Documentation" element={<Documentation />} />
      <Route path="/Developers" element={<Developers />} />
      <Route path="/Deposit" element={<Deposit />} />
      <Route path="/Withdrawal" element={<Withdrawal />} />
      <Route path="/Support" element={<Support />} />
    </Routes>
  </BrowserRouter>
);
