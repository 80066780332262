import { Badge, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

import { colors } from "./Colors";

export const theme = createTheme({
  status: {
    danger: colors.error,
  },
  palette: {
    primary: {
      main: colors.primary,
      darker: colors.primary,
    },
    secondary: {
      main: colors.secondary,
      darker: colors.secondary,
    },
  },
});

export const buttonStyle = Object.freeze({
  textTransform: "none",
});

export const NotificationBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: 6,
    top: 8,
  },
}));

export const CustomTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottomColor: colors.secondary,
  },
});
