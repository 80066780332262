import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useContext } from "react";
import { GlobalContext } from "../services/Context";
import { Get } from "../services/Services";
import { colors } from "../styles/Colors";
import classes from "../styles/components/EnvSwitch.module.css";

export const EnvSwitch = ({ containerStyle, textStyle }) => {
  const { prodEnv, setProdEnv, setAlert } = useContext(GlobalContext);

  const switchEnv = async (value) => {
    if (value) {
      const { success, data } = await Get("/prod_access");

      if (success) {
        if (data.authorized) {
          setProdEnv(value);
          sessionStorage.setItem("env", "production");
        } else {
          setAlert({
            show: true,
            message:
              "You don't have permission for this operation, please contact support@empowch.com",
            type: "error",
          });
        }

      } else {
        setAlert({
          show: true,
          message: "An error occurred while switching environment.",
          type: "error",
        });
      }

    } else {
      setProdEnv(value);
      sessionStorage.setItem("env", "staging");
    }
  };

  return (
    <div className={classes.mainContainer} style={containerStyle}>
      <div
        className={`${classes.env} ${!prodEnv && classes.activeEnv}`}
        style={textStyle}
      >
        Test
      </div>

      <EnvSwitchComponent
        checked={prodEnv}
        onChange={(e, value) => switchEnv(value)}
      />

      <div
        className={`${classes.env} ${prodEnv && classes.activeEnv}`}
        style={textStyle}
      >
        Production
      </div>
    </div>
  );
};

const EnvSwitchComponent = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    color: colors.primary,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#D9D9D9",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 8,
    opacity: 1,
    backgroundColor: "#D9D9D9",
    boxSizing: "border-box",
  },
}));
