import { RenderAvatarCell, renderStatusCell } from "../components/Table";
export const transactionsTableColumns = Object.freeze([
  {
    field: "TransactionID",
    headerName: "Transaction ID",
    flex: 0.5,
    sortable: false,
  },
  { field: "Date", headerName: "Date", flex: 0.5 },
  {
    field: "Recipient",
    headerName: "Recipient",
    flex: 1,
    renderCell: ({ row }) => <RenderAvatarCell value={row.Recipient} />,
    sortable: false,
  },

  {
    field: "request_amount",
    headerName: "Requested Amount",
    flex: 0.5,
    hide: true
  },
  { field: "sent_amount", headerName: "Sent Amount", flex: 0.5 },
  { field: "received_amount", headerName: "Received Amount", flex: 0.5 },
  {
    field: "Status",
    headerName: "Status",
    flex: 0.5,
    renderCell: renderStatusCell,
    sortable: false,
  },
]);
