import { createContext } from "react";

export const GlobalContext = createContext({
  userData: {},
  setUserData: () => {},

  language: false,
  setLanguage: () => {},

  prodEnv: false,
  setProdEnv: () => {},

  openNavbar: false,
  setOpenNavbar: () => {},

  alert: { show: false },
  setAlert: () => {},

  loading: false,
  setLoading: () => {},
});
