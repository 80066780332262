/**
 * clears the sessionStorage and redirects the user to the sign in page
 */
export const kickOut = () => {
  sessionStorage.clear();
  window.location.href = "/";
};

/**
 * checks if the token exists,
 * and either redirects them to the Dashboard or the SignIn page if necessary.
 * it is meant to be called in a useEffect.
 */
export const checkTokenExists = (redirectToDashboard=false) => {
  return () => {
    if (sessionStorage.getItem("token")) {
      if (redirectToDashboard) window.location.href = "/Dashboard";
    } else {
      if (!redirectToDashboard) kickOut();
    }
  };
};
