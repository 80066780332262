import { Button } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Parent } from "../components/Parent";
import { Table } from "../components/Table";
import { buttonStyle } from "../styles/muiStyles";
import classes from "../styles/pages/Dashboard.module.css";
import { GlobalContext } from "../services/Context";
import { checkTokenExists } from "../tools/security";
import { Get } from "../services/Services";
import { transactionsTableColumns } from "../data/TableColumns";
import {
  columns,
  BootstrapDialog,
  StyledDialogActions,
} from "../data/DialogData";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function Dashboard() {
  useEffect(checkTokenExists(), []);

  const { setAlert, userData, setLoading, prodEnv } = useContext(GlobalContext);

  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [dialogData, setDialogData] = useState([]);
  const [totalPending, setTotalPending] = useState(0);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [recipient, setRecipient] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = (props) => {
    getDialogData(props);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDialogData([]);
    setTotalPending(0);
    setTotalSuccess(0)
    setRecipient("")
  };

  const getDialogData = async (props) => {
    setTableLoading(true);
    let modelData = await Get(
      `/transactions?userID=${props.row.sender}&receiverID=${props.row.receiver}`,
      `v${props.userData.business_version}`
    );
    let transactions = modelData.data.transactions;
   
    setDialogData(
      transactions.map((transaction) => ({
        id: transaction.referenceNo,
        request_amount: `${transaction.request_amount}${
          transaction.receivedCurrency ? " " + transaction.receivedCurrency.name : " USDc"
        }`,
        received_amount: transaction.received_amount
        ? `${transaction.received_amount}${
           transaction.sentCurrency ? " " + transaction.sentCurrency.name : " USDc"
          }`
        : "TBD",
        sent_amount: transaction.sent_amount
        ? `${transaction.sent_amount}${
            transaction.sentCurrency ? " " + transaction.sentCurrency.name : " USDc"
          }`
        : "TBD",
        status: transaction.status,
        date: new Date(transaction.createdAt).toLocaleDateString("en-UK", {
          year: "2-digit",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }),
        Recipient: `${transaction.receiver.firstName} ${transaction.receiver.lastName}`,
      }))
    );
    let pendingTotal = 0;
    let successTotal = 0;

    // Calculate the totals
    transactions.forEach((transaction) => {
      if (transaction.status === "Pending") {
        pendingTotal += parseFloat(transaction.request_amount);
      } else if (transaction.status === "Success") {
        successTotal += parseFloat(transaction.request_amount);
      }
    });
    setRecipient(
      `${transactions[0].receiver.firstName} ${transactions[0].receiver.lastName}`
    );

    // Update the state with the calculated totals
    setTotalPending(pendingTotal);
    setTotalSuccess(successTotal);

    setTableLoading(false);
  };

  const getTransactions = async (sorting) => {
    setTableLoading(true);
    let endpoint;
    let version;
    if (userData.business_version === "1") {
      endpoint = `/transactions?sortBy=${
        sorting?.field.toLowerCase() || "date"
      }&userID= ${userData.user_id}&sortOrder=${sorting?.sort || "desc"}`;
      version = "v1";
    } else {
      endpoint = `/transactions?sortBy=${
        sorting?.field.toLowerCase() || "date"
      }&sortOrder=${sorting?.sort || "desc"}`;
      version = "v2";
    }
    let { success, data, error } = await Get(endpoint, version);

    if (success) {
      let transactions;
      ({ transactions, ...data } = data);
      setTableData(
        transactions
          ? userData.business_version === "1"
            ? transactions.map(
                ({
                  referenceNo,
                  createdAt,
                  receiver,
                  wallet,
                  sent_amount,
                  sentCurrency,
                  received_amount,
                  receivedCurrency,
                  request_amount,
                  status,
                  sender,
                }) => ({
                  id: referenceNo,
                  TransactionID: referenceNo,
                  Date: new Date(createdAt).toLocaleDateString("en-UK", {
                    year: "2-digit",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  }),
                  Recipient: {
                    avatar: receiver?.avatar,
                    name: receiver
                      ? `${receiver?.firstName} ${receiver?.lastName}`
                      : `${wallet?.address}${
                          wallet?.memo ? `:${wallet?.memo}` : ""
                        }`,
                  },
                  request_amount: `${request_amount}${
                    receivedCurrency ? " " + receivedCurrency.name : " USDc"
                  }`,
                  sent_amount: sent_amount
                    ? `${sent_amount}${
                        sentCurrency ? " " + sentCurrency.name : " USDc"
                      }`
                    : "TBD",
                  received_amount: received_amount
                    ? `${received_amount}${
                        sentCurrency ? " " + sentCurrency.name : " USDc"
                      }`
                    : "TBD",
                  Status: status,
                  sender: sender.id,
                  receiver: receiver.id,
                })
              )
            : transactions.map(
                ({
                  referenceNo,
                  createdAt,
                  sender,
                  wallet,
                  sent,
                  received,
                  status,
                }) => ({
                  id: referenceNo,
                  TransactionID: referenceNo,
                  Date: new Date(createdAt).toLocaleDateString("en-UK", {
                    year: "2-digit",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  }),
                  Recipient: {
                    avatar: sender?.avatar,
                    name: sender
                      ? `${sender?.firstName} ${sender?.lastName}`
                      : `${wallet?.address}${
                          wallet?.memo ? `:${wallet?.memo}` : ""
                        }`,
                  },
                  sent_amount: `${sent.amount} ${sent.currency?.toUpperCase()}`,
                  received_amount: `${
                    received.amount
                  } ${received.currency?.toUpperCase()}`,
                  Status: status,
                })
              )
          : []
      );
    } else {
      setAlert({
        show: true,
        message: error.message,
        type: "error",
      });
    }
    setTableLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { success, data, error } = await Get("/wallet?asset=USDC");
      setLoading(false);
      if (success) {
        let wallet = data.balances[0];
        setUserDetails({
          "Wallet ID": userData.wallet_address,
          Currency: wallet.assetName,
          "Available Balance": wallet.balance,
        });
      } else {
        setUserDetails({});
        setAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      }
    };
    fetchData();
    getTransactions();
  }, [prodEnv]);

  return (
    <Parent>
      <div className={classes.topDivContainer}>
        <div className={classes.topMessage}>Welcome back, {userData.name}!</div>
        <div className={classes.topDivEndContainer}>
          <Button
            variant="outlined"
            style={buttonStyle}
            onClick={() =>
              setAlert({ show: true, message: "Coming Soon!", type: "info" })
            }
          >
            Deposit
          </Button>
          <Button
            variant="contained"
            style={buttonStyle}
            onClick={() =>
              setAlert({ show: true, message: "Coming Soon!", type: "info" })
            }
          >
            Withdraw
          </Button>
        </div>
      </div>

      <div className={classes.detailsContainer}>
        {Object.entries(userDetails).map(([title, body], key) => (
          <div key={key} className={classes.detailsRow}>
            <span className={classes.detailsTitle}>{title}:</span>
            <span>{body}</span>
          </div>
        ))}
      </div>

      <div className={classes.tableInfoContainer}>
        <div>Latest Activities</div>
        <ArrowDownwardIcon />
      </div>

      <Table
        columns={transactionsTableColumns}
        rows={tableData}
        isLoading={tableLoading}
        onSortingChange={getTransactions}
        hideFooter
        hideRequestAmountColumn={userData.business_version !== "1"}
        onRowClick={(props) => {
          userData.business_version === "1" ? handleClickOpen({ ...props, userData }):""
        }}
      />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          All Transactions With <b>{recipient}</b> 
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Table
            columns={columns}
            rows={dialogData}
            isLoading={tableLoading}
            onSortingChange={getTransactions}
            hideFooter
            hideRequestAmountColumn={userData.business_version !== "1"}
          />
        </DialogContent>
        <StyledDialogActions>
          <div>
            <p>Pending Transactions: {totalPending}</p>
            <p>Success Transactions: {totalSuccess}</p>
          </div>
        </StyledDialogActions>
      </BootstrapDialog>
      
    </Parent>
  );
}
