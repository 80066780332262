import classes from "../styles/components/Icon.module.css";

export const Icon = ({ source, style, className }) => {
  return (
    <div className={`${classes.iconContainer} ${className}`} style={style}>
      <div
        className={classes.icon}
        style={{ backgroundImage: `url(${source})` }}
      />
    </div>
  );
};
