import React, { useContext, useEffect, useState } from "react";
// import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
// import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";

import { Parent } from "../components/Parent";
import classes from "../styles/pages/Developers.module.css";
import { buttonStyle } from "../styles/muiStyles";
import { SearchInput } from "../components/SearchInput";
import { Table } from "../components/Table";
import { FormDialog } from "../components/FormDialog";
import { Get, Post } from "../services/Services";
import { checkTokenExists } from "../tools/security";
import { GlobalContext } from "../services/Context";

export default function Developers() {
  useEffect(checkTokenExists(), []);

  const { setAlert, setLoading, prodEnv } = useContext(GlobalContext);

  const [tableLoading, setTableLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [keyName, setKeyName] = useState("");
  const [keys, setKeys] = useState({
    secret: "",
    public: "",
  });
  const [openTooltip, setOpenTooltip] = useState({
    PrivateKey: false,
    PublicKey: false,
  });
  const [tableData, setTableData] = useState([]);

  const createKey = async () => {
    setLoading(true);
    let { success, data, error } = await Post(`/api_keys`, { name: keyName });
    if (success) {
      setKeys(data.keys);
    } else {
      setAlert({
        show: true,
        message: error.message,
        type: "error",
      });
    }
    setLoading(false);
  };

  const getTransactions = async () => {
    setTableLoading(true);

    let { success, data, error } = await Get(
      `/api_keys` + (searchInput ? `?name=${searchInput}` : "")
    );

    if (success) {
      setTableData(
        data.keys.map(({ id, createdAt, name, publicKey }) => ({
          id: id,
          Date: new Date(createdAt).toLocaleDateString("en-UK", {
            year: "2-digit",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }),
          Name: name,
          Key: publicKey,
        }))
      );
    } else {
      setAlert({
        show: true,
        message: error.message,
        type: "error",
      });
    }
    setTableLoading(false);
  };

  useEffect(() => {
    getTransactions();
  }, [prodEnv]);

  return (
    <Parent>
      <div className={classes.topDiv}>
        <div className={classes.title}>API Keys</div>
        <div>
          <Button
            variant="contained"
            style={buttonStyle}
            onClick={() => setShowDialog(true)}
          >
            Add API Key
          </Button>
        </div>
      </div>
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <SearchInput
          label="Search by name..."
          value={searchInput}
          onChange={setSearchInput}
          onSearch={getTransactions}
        />
      </div>

      <div className={classes.tableTitle}>Recently Created</div>
      <Table
        isLoading={tableLoading}
        columns={[
          { field: "Date", headerName: "Date", flex: 1, sortable: false },
          {
            field: "Name",
            headerName: "Name",
            flex: 1,
            sortable: false,
          },
          {
            field: "Key",
            headerName: "Public Key",
            flex: 2,
            sortable: false,
          },
          {
            field: "Action",
            headerName: "Action",
            flex: 0.8,
            sortable: false,
            renderCell: ({row}) => (
              <div className={classes.actionsCell}>
                {/* <IconButton>
                  <ModeEditOutlinedIcon />
                </IconButton>

                <IconButton>
                  <DeleteOutlinedIcon />
                </IconButton> */}

                <IconButton
                  onClick={() => navigator.clipboard.writeText(row.Key)}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
              </div>
            ),
          },
        ]}
        rows={tableData}
        hideFooter
      />

      <FormDialog
        show={showDialog}
        onClose={() => {
          setKeys({
            secret: "",
            public: "",
          });
          setKeyName("");
          setShowDialog(false);
          getTransactions();
        }}
        hideDialogActions
        dialogTitle="Generate new API key"
      >
        <div className={classes.dialogContainer}>
          <TextField
            label="API Key Name"
            className={classes.textField}
            value={keyName}
            onChange={(e) => setKeyName(e.target.value)}
          />

          <div className={classes.dialogMessage}>
            Please make sure to store your private key as it will be hidden once
            this dialog is closed.
          </div>

          <TextField
            label="Public Key"
            className={classes.textField}
            value={keys.public}
            InputProps={{
              endAdornment: keys.public && (
                <InputAdornment position="end">
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() =>
                      setOpenTooltip({ ...openTooltip, PublicKey: false })
                    }
                    open={openTooltip.PublicKey}
                    disableFocusListener
                    disableTouchListener
                    title="Copied!"
                  >
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(keys.public);
                        setOpenTooltip({ ...openTooltip, PublicKey: true });
                      }}
                    >
                      <ContentCopyOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Private Key"
            className={classes.textField}
            value={keys.secret}
            InputProps={{
              endAdornment: keys.secret && (
                <InputAdornment position="end">
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() =>
                      setOpenTooltip({ ...openTooltip, PrivateKey: false })
                    }
                    open={openTooltip.PrivateKey}
                    disableFocusListener
                    disableTouchListener
                    title="Copied!"
                  >
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(keys.secret);
                        setOpenTooltip({ ...openTooltip, PrivateKey: true });
                      }}
                    >
                      <ContentCopyOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            style={{ ...buttonStyle, height: "max-content" }}
            onClick={createKey}
            disabled={!keyName || Boolean(keys.secret) || Boolean(keys.public)}
          >
            Create
          </Button>
        </div>
      </FormDialog>
    </Parent>
  );
}
