import { Button } from "@mui/material";
import { useState } from "react";
import { colors } from "../styles/Colors";

import classes from "../styles/components/ForgotPasswordForm.module.css";
import { buttonStyle, CustomTextField } from "../styles/muiStyles";
import { validateEmail } from "../tools/validation";
import { Icon } from "./Icon";

export const ForgotPasswordForm = ({ onSubmit }) => {
  const [emailError, setEmailError] = useState("");
  const [inputData, setInputData] = useState({});

  const sendEmail = () => {
    if (!validateEmail(inputData.email)) {
      setEmailError("Please enter a valid email.");
    } else {
      onSubmit(inputData);
    }
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.inputsContainer}>
        <div className={classes.mainLogoContainer}>
          <Icon source={require("../assets/logo.png")} />
        </div>

        <div className={classes.titleContainer}>
          <div className={classes.title}>Forgot Password</div>
          <div className={classes.subTitle}>
            You will receive an email with instructions to reset your password.
          </div>
        </div>

        <div className={classes.inputContainer}>
          <div className={classes.textFieldLabel}>Email</div>
          <CustomTextField
            placeholder="Enter your email"
            variant="standard"
            className={classes.textField}
            value={inputData.email || ""}
            onChange={(e) =>
              setInputData({ ...inputData, email: e.target.value })
            }
            sx={{
              "& .MuiInput-underline:before": {
                borderBottomColor: Boolean(inputData.email) && colors.secondary,
              },
              "& .MuiInput-underline": {
                color: Boolean(inputData.email) && colors.secondary,
              },
            }}
          />
          <div className={classes.invalidErrorContainer}>
            <div className={classes.invalidError}>{emailError}</div>
          </div>
        </div>

        <div className={classes.bottomRow}>
          <Button
            variant="contained"
            className={classes.button}
            style={buttonStyle}
            onClick={sendEmail}
          >
            Send Email
          </Button>
        </div>
      </div>
    </div>
  );
};
