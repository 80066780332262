import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { renderStatusDialogCell } from "../components/Table";

export const columns = [{
    field: "id",
    headerName: "Transaction ID",
    flex: 0.6,
    sortable: false,
  },
  {
    field: "date",
    headerName: "Date",
    flex: 0.3,
    sortable: false,
  },
  // {
  //   field: "Recipient",
  //   headerName: "Recipient",
  //   flex: 0.5,
  //   sortable: false,
  // },

  {
    field: "request_amount",
    headerName: "Requested Amount",
    flex: 0.3,
    hide: true
  },
  { field: "sent_amount", headerName: "Sent Amount", flex: 0.3 },
  { field: "received_amount", headerName: "Received Amount", flex: 0.3 },
  {
    field: "status",
    headerName: "Status",
    renderCell: renderStatusDialogCell,

    flex: 0.3,
    sortable: false,
  },
]
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
      width: "80%", 
      height: "auto", 
      maxWidth: "none", 
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
 export  const StyledDialogActions = styled(DialogActions)(() => ({
    padding: 0, 
    margin: 0,
    justifyContent: "flex-start", 
    fontSize:'small',
  }));
  