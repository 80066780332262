import { Parent } from "../components/Parent";

export default function Documentation() {
  return (
    <Parent>
      <div>Documentations page</div>
      <div>test</div>
      <div>test</div>
      <div>test</div>
    </Parent>
  );
}
