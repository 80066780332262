import { ThemeProvider } from "@mui/material";
import { useContext } from "react";

import classes from "../styles/components/Parent.module.css";
import { Navbar } from "./Navbar";
import { Header } from "./Header";
import { theme } from "../styles/muiStyles";
import { Alert } from "./Alert";
import { Loading } from "./Loading";
import { GlobalContext } from "../services/Context";

export const Parent = ({ children }) => {
  const { loading } = useContext(GlobalContext)
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.mainContainer}>
        <Header />
        <Navbar />
        <Alert />
        {loading&&<Loading />}

        {children}
      </div>
    </ThemeProvider>
  );
};
