import React, { useState } from "react";
import ReactDOM from "react-dom/client";

import { Routing } from "./routes/Routing";
import { GlobalContext } from "./services/Context";
import "./styles/index.css";

function Index() {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("userData") || "{}")
  );

  const [language, setLanguage] = useState(sessionStorage.getItem("lang") || 1);
  const [prodEnv, setProdEnv] = useState(
    sessionStorage.getItem("env") == "production"
  );
  const [alert, setAlert] = useState({ show: false });

  const [openNavbar, setOpenNavbar] = useState(false);

  const [loading, setLoading] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        userData: userData,
        setUserData: setUserData,

        language: language,
        setLanguage: setLanguage,

        prodEnv: prodEnv,
        setProdEnv: setProdEnv,

        openNavbar: openNavbar,
        setOpenNavbar: setOpenNavbar,

        alert: alert,
        setAlert: setAlert,

        loading: loading,
        setLoading: setLoading,
      }}
    >
      <Routing />
    </GlobalContext.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);
