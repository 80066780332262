import React, { useState, useContext, useEffect } from "react";
import { Parent } from "../components/Parent";
import classes from "../styles/pages/Support.module.css";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { buttonStyle } from "../styles/muiStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SearchInput } from "../components/SearchInput";
import { Table } from "../components/Table";
import { GlobalContext } from "../services/Context";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { FormDialog } from "../components/FormDialog";
import { Get, Post } from "../services/Services";
import { checkTokenExists } from "../tools/security";

export default function Support() {
  useEffect(checkTokenExists(), []);

  const [showPopup, setShowPopup] = useState(false);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [module, setModule] = useState("");
  const [description, setDescription] = useState("");
  const { setAlert, setLoading } = useContext(GlobalContext);
  const [searchInput, setSearchInput] = useState("");
  const [tableData, settableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const selecType = (e) => {
    setType(e.target.value);
  };
  const selectModule = (e) => {
    setModule(e.target.value);
  };
  const getTickets = async () => {
    setTableLoading(true);
    let { success, data, error } = await Get(
      `/tickets` + (searchInput ? `?id=${searchInput}` : ""), 
      null,
      true
    );

    if (success) {
      settableData(
        data.tickets.map(
          ({ id, description, title, status, type, module, createdAt }) => ({
            id: id,
            Date: new Date(createdAt).toLocaleDateString("en-UK", {
              year: "2-digit",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }),
            Title: title,
            Description: description,
            Status: status,
            Type: type,
            Module: module,
          })
        )
      );
    } else {
      setAlert({
        show: true,
        message: error.message,
        type: "error",
      });
    }
    setTableLoading(false);
  };
  const submitTicket = async () => {
    let userdata = sessionStorage.getItem("userData");
    let email = JSON.parse(userdata).email;
    setLoading(true);
    let { success, error } = await Post(`/tickets/create`, {
      type: type,
      module: module,
      title: title,
      description: description,
      email: email,
    }, true);
    if (success) {
      getTickets();
      setShowPopup(false);
      setDescription("");
      setModule("");
      setTitle("");
      setType("");
    } else {
      setAlert({
        show: true,
        message: error.message,
        type: "error",
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    getTickets();
  }, []);
  return (
    <Parent>
      <div className={classes.topDiv}>
        <div className={classes.title}>Tickets</div>
        <div>
          <Button variant="contained" style={buttonStyle} onClick={togglePopup}>
            Add Ticket
          </Button>
        </div>
      </div>
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <SearchInput
          label="Search Ticket ID..."
          value={searchInput}
          onChange={setSearchInput}
          onSearch={getTickets}
        />
      </div>

      <Table
        isLoading={tableLoading}
        columns={[
          { field: "id", headerName: "Ticket ID", flex: 0.5 },

          {
            field: "Date",
            headerName: "Date",
            flex: 1,
          },
          {
            field: "Title",
            headerName: "Title",
            flex: 1,
          },
          {
            field: "Type",
            headerName: "Type",
            flex: 1,
            renderCell: ({ row }) =>
              row.Type == "S" ? (
                <div>Suggestion</div>
              ) : row.Type == "Q" ? (
                <div>Question</div>
              ) : (
                <div>Issue</div>
              ),
          },
          {
            field: "Module",
            headerName: "Module",
            flex: 1,
          },
          {
            field: "Description",
            headerName: "Description",
            flex: 2,
          },
          {
            field: "Status",
            headerName: "Status",
            flex: 1,
            renderCell: ({ row }) =>
              row.Status == "C" ? (
                <div className={classes.statusCell}>Closed</div>
              ) : row.Status == "O" ? (
                <div className={classes.statusCell}>Opened</div>
              ) : (
                <div className={classes.statusCell}>Under Review</div>
              ),
          },
        ]}
        rows={tableData}
      />
      <FormDialog
        show={showPopup}
        onClose={() => {
          setShowPopup(false);
          setDescription("");
          setModule("");
          setTitle("");
          setType("");
        }}
        hideDialogActions
        dialogTitle="Submit a Ticket"
      >
        <div className={classes.dialogContainer}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-name-label">Type</InputLabel>
            <Select value={type} label="Type" onChange={selecType}>
              <MenuItem value={"I"}>Issue</MenuItem>
              <MenuItem value={"S"}>Suggestion</MenuItem>
              <MenuItem value={"Q"}>Question</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-name-label">Module</InputLabel>
            <Select value={module} label="Module" onChange={selectModule}>
              <MenuItem value={"Profile Registration"}>
                Profile registration
              </MenuItem>
              <MenuItem value={"Send and receive money"}>
                Send and receive money
              </MenuItem>
              <MenuItem value={"Bank transfers"}>Bank transfers</MenuItem>
              <MenuItem value={"Identity verification"}>
                Identity verification
              </MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Title"
            className={classes.textField}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Description"
            className={classes.textField}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={3}
          />

          <Button
            variant="contained"
            style={{ ...buttonStyle, height: "max-content" }}
            onClick={submitTicket}
            disabled={!title || !description || !module || !type}
          >
            Submit
          </Button>
        </div>
      </FormDialog>
    </Parent>
  );
}
