import { kickOut } from "../tools/security";

const config = require("../config/config.json");

export const getImageUrl = () => config.images;
const getUrl = () => config[sessionStorage.getItem("env") || "staging"]?.host;

const headers = () => ({
  // function to get new token every time
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: sessionStorage.getItem("token")||"",
});

export async function Post(endpoint, body, useProd = false) {
  let url = useProd ? config["production"]?.host : getUrl();
  if (!url) return;

  let response = await fetch(url + endpoint, {
    method: "POST",
    credentials: 'include',
    headers: headers(),
    body: JSON.stringify(body),
  });
  if (response.status == 401) kickOut();

  return await response.json();
}

export async function Get(endpoint, version, useProd = false) {
  let url = useProd ? config["production"]?.host : getUrl();
  if (!url) return;

  if(version) url = url.replace("v1", version)

  let response = await fetch(url + endpoint, {
    method: "GET",
    headers: headers(),
  });
  if (response.status == 401) kickOut();

  return await response.json();
}

export async function Patch(endpoint, body) {
  let url = getUrl();
  if (!url) return;

  let response = await fetch(url + endpoint, {
    method: "PATCH",
    headers: headers(),
    body: JSON.stringify(body),
  });
  if (response.status == 401) kickOut();

  return await response.json();
}

export async function Delete(endpoint, body) {
  let url = getUrl();
  if (!url) return;

  let response = await fetch(url + endpoint, {
    method: "DELETE",
    headers: headers(),
    body: JSON.stringify(body),
  });
  if (response.status == 401) kickOut();

  return await response.json();
}
