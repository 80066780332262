import { Button } from "@mui/material";
import { useState } from "react";
import { validateOTP } from "../tools/validation";
import classes from "../styles/components/ForgotPasswordForm.module.css";
import { buttonStyle, CustomTextField } from "../styles/muiStyles";
import { Icon } from "./Icon";
import { colors } from "../styles/Colors";

export const OTPForm = ({ onVerify }) => {
  const [inputData, setInputData] = useState({});
  const [otpError, setOTPError] = useState("");

  const verify = () => {
    if (!validateOTP(inputData.code)) {
      setOTPError("Please enter a valid otp.");
    } else {
      onVerify(inputData);
    }
  };
  return (
    <div className={classes.formContainer}>
      <div className={classes.inputsContainer}>
        <div className={classes.mainLogoContainer}>
          <Icon source={require("../assets/logo.png")} />
        </div>

        <div className={classes.titleContainer}>
          <div className={classes.title}>Verify phone number</div>
          <div className={classes.subTitle}>
            You will receive a sms with a code to verify your phone number and
            log in.
          </div>
        </div>

        <div className={classes.inputContainer}>
          <div className={classes.textFieldLabel}>6-digits code</div>
          <CustomTextField
            placeholder="Code"
            variant="standard"
            className={classes.textField}
            value={inputData.code || ""}
            onChange={(e) =>
              setInputData({ ...inputData, code: e.target.value })
            }
            sx={{
              "& .MuiInput-underline:before": {
                borderBottomColor: Boolean(inputData.code) && colors.secondary,
              },
              "& .MuiInput-underline": {
                color: Boolean(inputData.code) && colors.secondary,
              },
            }}
          />
          <div
            className={classes.forgotPasswordContainer}
            style={{
              justifyContent: "start",
              marginTop: ".2em",
            }}
          >
            <div className={classes.invalidError}>{otpError}</div>
          </div>
        </div>

        <div className={classes.bottomRow}>
          <Button
            variant="contained"
            className={classes.button}
            style={buttonStyle}
            onClick={verify}
          >
            Verify
          </Button>
        </div>
      </div>
    </div>
  );
};
