import { Paper, ThemeProvider } from "@mui/material";
import { useEffect, useContext } from "react";
import { GlobalContext } from "../services/Context";
import { useNavigate } from "react-router-dom";
import { Loading } from "../components/Loading";
import { Alert } from "../components/Alert";

import { ForgotPasswordForm } from "../components/ForgotPasswordForm";
import { LoginBackground } from "../components/LoginBackground";
import classes from "../styles/ForgotPassword.module.css";
import { theme } from "../styles/muiStyles";
import { checkTokenExists } from "../tools/security";
import { Post } from "../services/Services";

export default function ForgotPassword() {
  const { loading, setLoading, setAlert } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(checkTokenExists(true), []);

  const submit = async (body) => {
    setLoading(true);
    let { success, error } = await Post("/forgot_password", body,true);
    setLoading(false);

    if (success) {
      navigate("/ForgotPasswordVerification");
    } else {
      setAlert({ show: true, message: error.message, type: "error" });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.pageContainer}>
        <LoginBackground />

        <div className={classes.formPageContainer}>
          <Paper className={classes.formContainer} elevation={3}>
            <ForgotPasswordForm onSubmit={submit} />
          </Paper>
        </div>
      </div>
      <Alert />
      {loading && <Loading />}
    </ThemeProvider>
  );
}
