import { DataGrid } from "@mui/x-data-grid";
import { Avatar, LinearProgress } from "@mui/material";

import classes from "../styles/components/Table.module.css";
import { colors } from "../styles/Colors";
import { debounce } from "../tools/tools";
export const Table = ({
  sx = {},
  columns = [],
  rows = [],
  isLoading = false,
  onRowClick,
  onRowDoubleClick,
  hideFooter,
  rowCount = 0,
  onPaginationChange,
  onSortingChange,
  debounceTimer,
  hideRequestAmountColumn,
}) => {
  const filteredColumns = columns.filter(
    (column) => !hideRequestAmountColumn || column.field !== "request_amount"
  );
  return (
    <div className={classes.mainContainer}>
      <div className={classes.tableContainer}>
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          rows={rows}
          columns={filteredColumns}
          autoHeight
          loading={isLoading}
          components={{ LoadingOverlay: LinearProgress }}
          rowCount={rowCount}
          paginationMode="server"
          sortingMode="server"
          pageSizeOptions={[10]}
          onSortModelChange={(model) => onSortingChange(model[0])}
          onPaginationModelChange={({ page }) =>
            debounce(() => onPaginationChange(page + 1), debounceTimer)()
          }
          onPage
          onRowClick={onRowClick}
          onRowDoubleClick={onRowDoubleClick}
          hideFooter={hideFooter}
          hideFooterSelectedRowCount
          disableColumnSelector
          disableColumnFilter
          sx={{
            ".MuiDataGrid-columnHeaderTitle": {
              color: colors.primary,
            },
            border: "none",
            ...sx,
          }}
        />
      </div>
    </div>
  );
};

export const RenderAvatarCell = ({ value }) => (
  <div className={classes.avatarCell}>
    <Avatar
      sx={{
        width: "26px",
        height: "26px",
        border: `2px solid ${colors.secondary}`,
      }}
      src={value.avatar}
    >
      {value.avatar}
    </Avatar>
    {value.name}
  </div>
);

export const renderStatusCell = ({ value }) => (
  <div className={classes.statusCell}>{value}</div>
);

export const renderStatusDialogCell = ({ value }) => {
  let color = "black";

  if (value === "Pending") {
    color = "#ffc107";
  } else if (value === "Success") {
    color = "#4caf50";
  }else if (value === "Rejected") {
    color = "red";
  }

  return (
    <div className={classes.statusDialogCell} style={{ color }}>
      {value}
    </div>
  );
};
